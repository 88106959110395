import {useEffect} from 'react';
import { navigate } from 'gatsby';

const Fullstack = () => {
    useEffect(() => {
        navigate('/jobs_fullstack');
      }, []);
      return null;
}

export default Fullstack
